import { React, useEffect, useState, useRef } from "react";
import { Helmet } from 'react-helmet';
import GoogleFbImg from '../assets/googlefb.png.avif'
import testImg from "../assets/searchHeader.png.avif"
import Header from "./Header";
import '../styles/Homepage.scss'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import HomepageSlider from "./HomepageSlider";
import HomepageVideoSlider from "./HomepageVideoSlider";
import HomepageBackgroundColorSection from "./HomepageBackgroundColorSection";
import HomepageVacationForTravellers from "./HomepageVacationForTravellers";
import HomepageCreative from "./HomepageCreative";
import Footer from "./Footer";
import icelandImg from '../assets/EuropeanHolidays/iceland.avif';
import turkey01Img from '../assets/EuropeanHolidays/turkey.avif';
import italy02Img from '../assets/EuropeanHolidays/italy.avif';
import turkey03Img from '../assets/EuropeanHolidays/turkey2.avif';
import austriaImg from '../assets/EuropeanHolidays/austria.avif';
import norwayImg from '../assets/EuropeanHolidays/norway.avif';
import finland02Img from '../assets/EuropeanHolidays/finland.avif';
import spain01Img from '../assets/EuropeanHolidays/spain 01.webp';
import franceImg from '../assets/EuropeanHolidays/france.webp';
import switzerlandImg from '../assets/EuropeanHolidays/switerland.webp';
import goaImg from '../assets/Domestic/goa.avif';
import pondiImg from '../assets/Domestic/pondi.avif';
import himachal01Img from '../assets/Domestic/himachal 01.webp';
import rajasthanImg from '../assets/Domestic/rajasthan.avif';
import himachal02Img from '../assets/Domestic/himachal 02.avif';
import arunachalImg from '../assets/Domestic/arunachal.avif';
import himachalImg from '../assets/Domestic/himachal.avif';
import tamilnadu01Img from '../assets/Domestic/tamilnadu-01.avif';
import assamImg from '../assets/Domestic/assam.avif';
import jammuImg from '../assets/Domestic/jammu.avif';
import tamilnaduImg from '../assets/Domestic/tamilnadu.webp';
import bengalImg from '../assets/Domestic/bengal.avif';
import keralaImg from '../assets/Domestic/kerala.avif';
import telanganaImg from '../assets/Domestic/telangana.avif';
import delhiImg from '../assets/Domestic/delhi.avif';
import karnatakaImg from '../assets/Domestic/karnataka.avif';
import nagalandImg from '../assets/Domestic/nagaland.avif';
import uttarakhandImg from '../assets/Domestic/uttarakhand.avif';
import bahrainImg from '../assets/Middleeast/bahrain.avif';
import qatarImg from '../assets/Middleeast/qatar.avif';
import abuDhabiImg from '../assets/Middleeast/abu dhabi.avif';
import dubaiImg from '../assets/VisaArrival/dubai.avif';
import saudiImg from '../assets/Middleeast/saudi.avif';
import melbourneImg from '../assets/Oceania/melbourne.avif';
import perthImg from '../assets/Oceania/perth.avif';
import sydneyImg from '../assets/Oceania/sydney.avif';
import fijiImg from '../assets/Oceania/fiji.avif';
import newZealandImg from '../assets/Oceania/newzealand.avif';
import queenslandImg from '../assets/Oceania/queensland.avif';
import tahitiImg from '../assets/Oceania/tahiti.avif';
import qgaKerala from '../assets/QuickGetaway/kerala.avif';
import qgaGoa from '../assets/QuickGetaway/goa.avif';
import qgaMumbai from '../assets/QuickGetaway/mumbai.avif';
import qgaAgra from '../assets/QuickGetaway/agra.avif';
import qgaHimachal from '../assets/QuickGetaway/himachal.avif';
import qgaTamilNadu from '../assets/QuickGetaway/tamilnadu.avif';
import finlandImg from '../assets/Scandinavia/finland.webp';
import norway02Img from '../assets/Scandinavia/norway (2).webp';
import sweden02Img from '../assets/Scandinavia/sweden (2).webp';
import denmarkImg from '../assets/Scandinavia/denmark.webp';
import swedenImg from '../assets/Scandinavia/sweden.webp';
import indonesiaImg from '../assets/VisaArrival/indonesia.avif';
import NepalImg from '../assets/VisaArrival/Nepal-home.avif';
import singaporeImg from '../assets/VisaArrival/singapore.avif';
import lakshadweepImg from '../assets/Domestic/Lakshaweep.avif';
import srilankaImg from '../assets/VisaArrival/srilanka.avif';
import maldivesImg from '../assets/VisaArrival/maldives.avif';
import thailandImg from '../assets/VisaArrival/thailand.avif';
import andamanImg from '../assets/VisaArrival/andaman.webp';
import maritiusImg from '../assets/VisaArrival/maritius.avif';
import vietnamImg from '../assets/VisaArrival/vietnam.avif';
import FooterMenu from "./FooterMenu";
import CarasoulCards from "./CarasoulCards";
import Landscape1 from '../assets/Landscape/landscape1.png.avif'
import Landscape2 from '../assets/Landscape/landscape2.png.avif'
import TrustTheExperts from "./TrustTheExperts";
import NorthIndia from '../assets/North india.png.avif'
import southIndia from '../assets/South india.png.avif'
import HoneyMoon from '../assets/Honeymoon.png.avif'
import spiritual from '../assets/Spiritual.png.avif'
// ZZAA
// import welcomeFlight from '../assets/welcome flight (7).png'
import welcomeFlight from '../assets/welcome.png.avif'
import jsonData from '../json/Data.json';
import HomepageBackgroundColorSectionWeb from "./HomepageBackgroundColorSectionWeb";

// CREATIVES IMPORT
import GEORGIA43creative from '../assets/creatives/GEORGIA 4.3.avif';
import KERALA1080Wcreative from '../assets/creatives/KERALA 1080 W.avif';
import Lakshadweep1creative from '../assets/creatives/Lakshadweep (1).avif';
import Maldives1080Wcreative from '../assets/creatives/Maldives 1080 W.avif';
import Wayanadcreative from '../assets/creatives/Wayanad.avif';
import agraCreative from '../assets/creatives/agra.avif';
import alleppeyCreative from '../assets/creatives/alleppey.avif';
import anfamanNicobarCreative from '../assets/creatives/anfaman & nicobar.avif';
import armania1350Creative from '../assets/creatives/armania 1350.avif';
import cochinKumarakomCreative from '../assets/creatives/cochin kumarakom.avif';
import coorgTourCreative from '../assets/creatives/coorg tour.avif';
import goaGirls1Creative from '../assets/creatives/goa girls 1.avif';
import goaWCreative from '../assets/creatives/goa w.avif';
import goaCreative from '../assets/creatives/goa.avif';
import haridwarRishikeshCreative from '../assets/creatives/haridwar rishikesh.avif';
import hyderabadCreative from '../assets/creatives/hyderabad.avif';
import jaipurCreative from '../assets/creatives/jaipur.avif';
import karnatakaTour1Creative from '../assets/creatives/karnataka tour (1).avif';
import keralaW1Creative from '../assets/creatives/kerala w (1).avif';
import keralaWCreative from '../assets/creatives/kerala w.avif';
import kodaikanalCreative from '../assets/creatives/kodaikanal.avif';
import kolkataCreative from '../assets/creatives/kolkata.avif';
import maldivesHoneymoonCreative from '../assets/creatives/maldives honeymoon.avif';
import maldivesWCreative from '../assets/creatives/maldives w.avif';
import munnarCreative from '../assets/creatives/munnar.avif';
import mysoreCreative from '../assets/creatives/mysore.avif';
import northIndiaTour1Creative from '../assets/creatives/north india tour (1).avif';
import northIndiaTourCreative from '../assets/creatives/north india tour.avif';
import ootyCreative from '../assets/creatives/ooty.avif';
import panja1080WCreative from '../assets/creatives/panja 1080 W.avif';
import panjaBoodthastalamWCreative from '../assets/creatives/panja boodthastalam w.avif';
import pattayaBangkokCreative from '../assets/creatives/pattaya bangkok.avif';
import pondicherryWCreative from '../assets/creatives/pondicherry w.avif';
import pondicherryCreative from '../assets/creatives/pondicherry.avif';
import puriKonarkCreative from '../assets/creatives/puri konark.avif';
import shiridiCreative from '../assets/creatives/shiridi.avif';
import southIndiaTour1Creative from '../assets/creatives/south india tour (1).avif';
import southIndiaTourCreative from '../assets/creatives/south india tour.avif';
import srilankaWCreative from '../assets/creatives/srilanka w.avif';
import tanjoreCreative from '../assets/creatives/tanjore.avif';
import thailand1Creative from '../assets/creatives/thailand (1).avif';
import thailand2Creative from '../assets/creatives/thailand (2).avif';
import thekkadyCreative from '../assets/creatives/thekkady.avif';
import varanasiCreative from '../assets/creatives/varanasi.avif';
import vietnamCreative from '../assets/creatives/vietnam.avif';
import wayanadTourCreative from '../assets/creatives/wayanad tour.avif';
import HomepageSliderCreatives from "./HomepageSliderCreatives";
import ContactContiki from "./ContactContiki";
import $ from 'jquery'

const creatives = [
  {
      title: "Georgia",
      img: GEORGIA43creative
  },
  {
      title: "Kerala",
      img: KERALA1080Wcreative
  },
  {
      title: "Lakshadweep",
      img: Lakshadweep1creative
  },
  {
      title: "Maldives",
      img: Maldives1080Wcreative
  },
  {
      title: "Thailand",
      img: thailand2Creative
  },
  {
      title: "Wayanad",
      img: Wayanadcreative
  },
  {
      title: "Agra",
      img: agraCreative
  },
  {
      title: "Alleppey",
      img: alleppeyCreative
  },
  {
      title: "Andaman",
      img: anfamanNicobarCreative
  },
  {
      title: "Armania",
      img: armania1350Creative
  },
  {
      title: "Cochin",
      img: cochinKumarakomCreative
  },
  {
      title: "Coorg",
      img: coorgTourCreative
  },
  {
      title: "Goa",
      img: goaWCreative
  },
  {
      title: "Goa",
      img: goaGirls1Creative
  },
  {
      title: "Goa",
      img: goaCreative
  },
  {
      title: "Haridwar",
      img: haridwarRishikeshCreative
  },
  {
      title: "Hyderabad",
      img: hyderabadCreative
  },
  {
      title: "Jaipur",
      img: jaipurCreative
  },
  {
      title: "Karnataka",
      img: karnatakaTour1Creative
  },
  {
      title: "Kerala",
      img: keralaW1Creative
  },
  {
      title: "Kerala",
      img: keralaWCreative
  },
  {
      title: "Kodaikanal",
      img: kodaikanalCreative
  },
  {
      title: "Kolkata",
      img: kolkataCreative
  },
  {
      title: "Maldives",
      img: maldivesHoneymoonCreative
  },
  {
      title: "Maldives",
      img: maldivesWCreative
  },
  {
      title: "Munnar",
      img: munnarCreative
  },
  {
      title: "Mysore",
      img: mysoreCreative
  },
  {
      title: "North India",
      img: northIndiaTour1Creative
  },
  {
      title: "North India",
      img: northIndiaTourCreative
  },
  {
      title: "Ooty",
      img: ootyCreative
  },
  {
      title: "Panja",
      img: panja1080WCreative
  },
  {
      title: "Panja Boodthastalam",
      img: panjaBoodthastalamWCreative
  },
  {
      title: "Pattaya Bangkok",
      img: pattayaBangkokCreative
  },
  {
      title: "Pondicherry",
      img: pondicherryWCreative
  },
  {
      title: "Pondicherry",
      img: pondicherryCreative
  },
  {
      title: "Puri Konark",
      img: puriKonarkCreative
  },
  {
      title: "Shiridi",
      img: shiridiCreative
  },
  {
      title: "South India",
      img: southIndiaTour1Creative
  },
  {
      title: "South India",
      img: southIndiaTourCreative
  },
  {
      title: "Srilanka",
      img: srilankaWCreative
  },
  {
      title: "Tanjore",
      img: tanjoreCreative
  },
  {
      title: "Thailand",
      img: thailand1Creative
  },
  {
      title: "Thailand",
      img: thailand2Creative
  },
  {
      title: "Thekkady",
      img: thekkadyCreative
  },
  {
      title: "Varanasi",
      img: varanasiCreative
  },
  {
      title: "Vietnam",
      img: vietnamCreative
  },
  {
      title: "Wayanad",
      img: wayanadTourCreative
  }
];

export const northIndiaData= [
  {
    id: 1,
    img: goaImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Goa",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 4,
    img: rajasthanImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Rajasthan",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 5,
    img: himachal02Img,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Himachal",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 6,
    img: arunachalImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Arunachal",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 7,
    img: himachalImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Himachal",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 9,
    img: assamImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Assam",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 10,
    img: jammuImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Jammu",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 12,
    img: bengalImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Bengal",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 15,
    img: delhiImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Delhi",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 16,
    img: nagalandImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Nagaland",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 17,
    img: uttarakhandImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Uttarakhand",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
]
export const southIndiaData = [
  {
    id: 1,
    img: lakshadweepImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Lakshadweep",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 2,
    img: pondiImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Pondicherry",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 8,
    img: tamilnadu01Img,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Tamil Nadu",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  }, 
  {
    id: 13,
    img: keralaImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Kerala",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 14,
    img: telanganaImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Telangana",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
  {
    id: 14,
    img: karnatakaImg,
    video: "/assets/video/banner_tour.mp4",
    badge: "offer",
    price: 320,
    discount: 210,
    title: "Karnataka",
    desc: "Lorem ipsum dolor sit amet, consectetur est.",
    offers: "5 days | 6 nights",
    person: "2 person",
  },
]

export const VisaArrivalData = [
  {
    img: NepalImg,
    title: "Nepal",
  },
  {
    img: indonesiaImg,
    title: "Indonesia",
  },
  {
    img: singaporeImg,
    title: "Singapore",
  },
  {
    img: lakshadweepImg,
    title: "Lakshadweep",
  },
  {
    img: srilankaImg,
    title: "Sri Lanka",
  },
  {
    img: maldivesImg,
    title: "Maldives",
  },
  {
    img: thailandImg,
    title: "Thailand",
  },
  {
    img: andamanImg,
    title: "Andaman",
  },
  {
    img: maritiusImg,
    title: "Maritius",
  },
  {
    img: vietnamImg,
    title: "Vietnam",
  },
];
    
export const ScandinaviaData = [
  {
    img: finlandImg,
    title: "Finland",
  },
  {
    img: norway02Img,
    title: "Norway",
  },
  {
    img: sweden02Img,
    title: "Sweden",
  },
  {
    img: denmarkImg,
    title: "Denmark",
  },
  {
    img: icelandImg,
    title: "Iceland",
  },
  {
    img: norwayImg,
    title: "Norway",
  },
  {
    img: swedenImg,
    title: "Sweden",
  },
];
    
export const oceaniaData = [
      {
        img: melbourneImg,
        title: "Melbourne",
      },
      {
        img: perthImg,
        title: "Perth",
      },
      {
        img: sydneyImg,
        title: "Sydney",
      },
      {
        img: fijiImg,
        title: "Fiji",
      },
      {
        img: newZealandImg,
        title: "New Zealand",
      },
      {
        img: queenslandImg,
        title: "Queensland",
      },
      {
        img: tahitiImg,
        title: "Tahiti",
      },
    ];


function Homepage () {
  const VacationForAll = [
    {
      title: "North India",
      img: NorthIndia
    },
    {
      title: "South India",
      img: southIndia
    },
    {
      title: "Honeymoon",
      img: HoneyMoon
    },
    {
      title: "Spiritual",
      img: spiritual
    }
  ]
      
      const MiddleEastData = [
        {
          img: bahrainImg,
          title: "Bahrain",
        },
        {
          img: qatarImg,
          title: "Qatar",
        },
        {
          img: abuDhabiImg,
          title: "Abu Dhabi",
        },
        {
          img: dubaiImg,
          title: "Dubai",
        },
        {
          img: saudiImg,
          title: "Saudi",
        },
      ];

    const europeanData = [
      {
        id: 1,
        img: icelandImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Iceland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 2,
        img: turkey01Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Turkey",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 3,
        img: italy02Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Italy",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 4,
        img: turkey03Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Turkey",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 5,
        img: austriaImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Austria",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 6,
        img: norwayImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Norway",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      // {
      //   id: 7,
      //   img: turkeyImg,
      //   video: "/assets/video/banner_tour.mp4",
      //   badge: "offer",
      //   price: 320,
      //   discount: 210,
      //   title: "Turkey",
      //   desc: "Lorem ipsum dolor sit amet, consectetur est.",
      //   offers: "5 days | 6 nights",
      //   person: "2 person",
      // },
      {
        id: 8,
        img: finland02Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Finland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 9,
        img: spain01Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Spain",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 10,
        img: franceImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "France",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 11,
        img: switzerlandImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Switzerland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
    ];
    const newOffersData = [
        {
          id: 1,
          img: testImg,
          video: "/assets/video/banner_tour.mp4",
          badge: "offer",
          price: 320,
          discount: 210,
          title: "hot air balloon",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Cool water ride",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "water fall",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Cool water ride",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
      ];
      const videoData = [
        {
          id: 1,
          // ZZAA
          img: Landscape1,
          // img: Landscape3,
          badge: "MALDIVES",
          price: 320,
          discount: 210,
          title: "Maldives Package",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          // ZZAA
          img: Landscape2,
          // img: Landscape4,
          badge: "MALDIVES",
          price: 320,
          discount: 210,
          title: "Spain Package",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
      ];
      const quickGetawayData = [
        {
          id: 1,
          img: qgaAgra,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Agra",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: qgaKerala,
          video: "/assets/video/banner_tour.mp4",
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Kerala",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: qgaGoa,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Goa",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: qgaHimachal,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Himachal",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
            id: 1,
            img: qgaMumbai,
            video: "/assets/video/banner_tour.mp4",
            badge: "offer",
            price: 320,
            discount: 210,
            title: "Mumbai",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
          {
            id: 1,
            img: qgaTamilNadu,
            badge: "offer",
            price: 320,
            discount: 210,
            title: "Tamil Nadu",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
      ];
    useEffect(() => {
      const handleClick = (event) => {
        if (
          !searchBoxRef.current.contains(event.target) &&
          event.target !== searchBoxRef.current
        ) {
          setShowSuggestions(false)
          console.log('Screen clicked');
        }
      };
    
        // Add click event listener to document
        document.addEventListener('click', handleClick);

        gsap.registerPlugin(ScrollTrigger);
    
        const init = () => {
          const marquee = document.querySelector('#marquee');
    
            const marqueeInner = marquee.querySelector('.marquee__inner');
            const marqueeContent = marqueeInner.querySelector('.marquee__content');
  
            // Duration
            const duration = marquee.getAttribute('data-marquee-duration');
            // const duration = 2
  
    
            // // Element Clone
            // const marqueeContentClone = marqueeContent.cloneNode(true);
            // marqueeInner.append(marqueeContentClone);
    
            // Marquee animation
            const marqueeContentAll = marqueeInner.querySelectorAll('.marquee__content');
            marqueeContentAll.forEach(element => {
              gsap.to(element, {
                x: '-101%',
                repeat: -1,
                duration: duration,
                ease: 'linear',
              });
            // });
    
            gsap.to('.marquee__inner', {
              xPercent: -10,
              scrollTrigger: {
                trigger: '.section__marquee',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
              },
            });
          });
        };
    
        init();
      },[]);

      useEffect(() => {
        function closeForm() {
                  $('.form-popup-bg').removeClass('is-visible');
        }

        $(document).ready(function($) {

        /* Contact Form Interactions */
        $('#btnOpenForm').on('click', function(event) {
            event.preventDefault();

            $('.form-popup-bg').addClass('is-visible');
        });

            //close popup when clicking x or off popup
        $('.form-popup-bg').on('click', function(event) {
            if ($(event.target).is('.form-popup-bg') || $(event.target).is('#btnCloseForm')) {
            event.preventDefault();
            $(this).removeClass('is-visible');
            }
        });
        });

            const allPlaces = jsonData.map(item => item.places_covered.split(',')
            .map(place => place.trim()) // Trim spaces from the beginning and end
            .map(place => place.charAt(0).toUpperCase() + place.slice(1).toLowerCase()) // Capitalize first letter
        ).flat();
            const uniquePlaces = [...new Set(allPlaces)]; 
            setSuggestions(uniquePlaces)
      }, []);


      const [searchInput, setSearchInput] = useState('');
      const [suggestions, setSuggestions] = useState(['Delhi', 'Amritsar', 'Mumbai', 'Jaipur', 'Chennai', 'Bangalore']);
      const [showSuggestions, setShowSuggestions] = useState(false);
      const [removeMarquee, setremoveMarquee] = useState(true);
      const dropdownRef = useRef(null);
      const searchBoxRef = useRef(null);

      const handleInputChange = (event) => {
        const inputValue = event.target.value.toLowerCase();
        setSearchInput(inputValue);
        handleSearchClick();
        const matchingSuggestions = suggestions.filter(suggestion => suggestion.toLowerCase().includes(searchInput));
        setSuggestions(matchingSuggestions);
        setShowSuggestions(true);
      };


      const handleSearchClick = () => {
        // Filter and set matching suggestions
        const matchingSuggestions = suggestions.filter(suggestion => suggestion.toLowerCase().includes(searchInput));
        setSuggestions(matchingSuggestions);
        setremoveMarquee(false)
        // Show suggestions
        setShowSuggestions(true);
      };
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          window.location.href = `/search/${searchInput}`;
        }
        else{
          const matchingSuggestions = suggestions.filter(suggestion => suggestion.toLowerCase().includes(searchInput));
        setSuggestions(matchingSuggestions);
          setShowSuggestions(true);
        }
      };

      const handleSuggestionClick = (suggestion) => {
        // Set selected suggestion in the input
        setSearchInput(suggestion);
        // Clear suggestions and hide dropdown
        setSuggestions([]);
        // setShowSuggestions(false);
      };
      // useEffect(() => {
      //   const handleClickOutside = (event) => {
      //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      //       // Clicked outside the dropdown, handle accordingly (e.g., close dropdown)
      //     }
      //   };
      
      //   document.addEventListener('mousedown', handleClickOutside);
      
      //   return () => {
      //     document.removeEventListener('mousedown', handleClickOutside);
      //   };
      // }, [dropdownRef]);
      const [formData, setFormData] = useState(() => {
        // Initialize form data from local storage or default values
        const storedData = localStorage.getItem('formData');
        return storedData ? JSON.parse(storedData) : {
          how_many_people: "",
          how_many_weeks: '',
          travel_when: '',
          travel_where: '',
          budget: 0,
          firstname: '',
          lastname: '',
          email:'',
          trip:'',
          mobile:'',
          contactBy:'',
          package_name:''
        };
      });
    
      const updateFormData = (key, value) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: value,
        }));
      };
    
      useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
      }, [formData]);

  const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Welcome Tours and Travels",
        "url": "https://www.welcometoursandtravels.com",
        "logo": {
            "@type": "ImageObject",
            "url": "https://www.welcometoursandtravels.com/path/to/logo.png" // Replace with actual logo URL
        },
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.welcometoursandtravels.com/search/{search_term_string}",
            "query-input": "required name=search_term_string"
        },
        "description": "The best travel agency for all your touring needs."
    }

    return(
      <>
            <div style={{ minWidth: "100%", position: "relative", display: "block", backgroundColor: "#f8f8f8" }}>
      <Header />
      <div style={{ top: "0", left: "0", width: "100%", position: "relative", height: "80vh" }}>
        <Helmet>
          <link rel="preconnect" href="https://ik.imagekit.io" />
          <link rel="preload" as="image" fetchpriority="high" href="https://ik.imagekit.io/rithik/tr:w-1200/bali.avif?w=1200" />
          <link rel="preload" as="image" fetchpriority="high" href="https://ik.imagekit.io/rithik/tr:w-768/bali.avif?w=768" />
          <meta name="robots" content="index, nofollow" />
          <link rel="canonical" href="https://welcometoursandtravels.com/" />
           <script type="application/ld+json">
                    {JSON.stringify(schemaMarkup)}
                </script>
        </Helmet>

        {/* Skeleton Loader */}
        <div style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "#e0e0e0", // Placeholder color
          borderRadius: "8px", // Optional: rounded corners
          transition: "background 0.3s ease" // Smooth transition for loading
        }} />

        <img
          alt="Bali"
          className="main-img"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            maxWidth: "100%",
            objectFit: "cover",
            width: "100%",
            height: "100%",
            opacity: 0, // Start invisible
            transition: "opacity 0.1s ease" // Smooth fade-in effect
          }}
          src="https://ik.imagekit.io/rithik/bali.avif"
          srcSet="
            https://ik.imagekit.io/rithik/tr:w-320/bali.avif 320w,
            https://ik.imagekit.io/rithik/tr:w-420/bali.avif?w=420 420w,
            https://ik.imagekit.io/rithik/tr:w-768/bali.avif?w=768 768w,
            https://ik.imagekit.io/rithik/tr:w-1024/bali.avif?w=1024 1024w,
            https://ik.imagekit.io/rithik/tr:w-1200/bali.avif?w=1200 1200w
          "
          sizes="(max-width: 767px) 100vw, (min-width: 768px) 80vw"
          decoding="async"
          data-nimg="fill"
          loading="eager"
          onLoad={(e) => {
            e.target.style.opacity = 1; // Fade in when the image loads
          }}
        />
        <div style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          pointerEvents: "none"
        }}></div>
      </div>


                <div style={{position:"absolute", top:"58%", left:"50%", transform:"translate(-50%, -50%)", color:"white", textAlign:"center", minWidth:"90%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                  <h1 style={{display:"none"}}>Welcome Tours and Travels Chennai</h1>
                  <h2 style={{display:"none"}}>Best tour packages in Chennai</h2>
                  <h3 style={{display:"none"}}>Best tour packages in Chennai</h3>
                  <b style={{display:"none"}}>Best tour packages in Chennai</b>
                    <div style={{fontFamily:"Kalnia", fontWeight:"bold"}} className="font-size-72-26">
                        {/* Plan your next <span style={{fontFamily:"Dancing Script", color:"#12765e"}}>Hassle-free</span> holiday */}
                        Your Dream Vacation<br/>Is Finally Here
                    </div>
                    <div style={{marginTop:"20px", border:"3px solid white", backgroundColor:"white", textAlign:"left", padding:"0 5%", borderRadius:"4px"}} className="home-banner-white-box">
                        <a href="#" style={{textDecoration:"none", color:"black"}}>
                                <div style={{borderBottom:"1px solid lightgrey"}}><div className="destination-word-homepage">DESTINATION </div></div>
                                <div className="font-size-24-18 where-like-go-homepage">Where would you like to go?</div>
                            <div className="flex-row-column">
                              <div className="search-box-home" style={{backgroundColor:"white", display:"flex", alignItems:"center", justifyContent:"flex-start", border:"1px solid grey", borderRadius:"4px"}}>

                                  <div style={{ position: 'relative', width:"100%", height:"100%" }}>
                                        <input
                                          type="search"
                                          placeholder="e.g. Delhi, Amritsar"
                                          ref={searchBoxRef}
                                          value={searchInput}
                                          onChange={handleInputChange}
                                          onClick={handleSearchClick}
                                          onKeyDown={handleKeyPress}
                                          style={{ border: 'none', minWidth: '100%', minHeight: '100%', backgroundColor: 'white', outlineOffset:"0px", padding:"2%" }}
                                        />

                                        {showSuggestions && suggestions.length > 0 && (
                                          <div style={{position:"relative", zIndex:"9999999"}}>
                                            <div
                                              style={{
                                                position: 'absolute',
                                                border: '1px solid #ccc',
                                                maxHeight: '250px',
                                                overflowY: 'auto',
                                                width: '100%',
                                                backgroundColor: 'white',
                                                borderRadius:"6px",
                                                boxShadow:"0px 0px 20px 4px rgba(0, 0, 0, 0.06)",
                                                marginTop:"1vh", zIndex:"999999999999"
                                              }}
                                            >
                                              {/* <ul style={{textDecoration:"none", listStyle:"none", minWidth:"100%"}}> */}
                                              {suggestions.map((suggestion, index) => (
                                                <div className="hover-color" style={{display:"flex", flexDirection:"column", zIndex:"999999999999"}}>
                                                    <a
                                                      key={index}
                                                      style={{ padding: '8px', cursor: 'pointer', minWidth:"100%", fontFamily:"Lato", fontWeight:"500", zIndex:"999999999999" }}
                                                      onClick={() => handleSuggestionClick(suggestion)}
                                                      href={`/search/${suggestion}`}
                                                    >
                                                      {suggestion}
                                                    </a>
                                                </div>
                                              ))}
                                              {/* </ul> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                              </div>
                              <div className="plan-your-trip-btn" style={{backgroundColor:"#11866C", display:"flex", alignItems:"center", justifyContent:"center", padding:"2% 2%", maxHeight:"30px", color:"white", borderRadius:"4px", boxShadow: "0 1px 4px 0 rgba(63,65,68,.3)", fontWeight:"bolder"}}>
                                <a href={`/search/${searchInput}`}><div style={{padding:"3% 0", color:"white"}}>PLAN YOUR TRIP</div></a>
                              </div>
                            </div>
                        </a>
                    </div> 

                </div>
                 {/* <div className="glaze-effect" style={{ position: "relative" }}> */}
                  <section style={{marginTop:"-5px", backgroundColor:"black", color:"white", whiteSpace:"nowrap", minHeight:"2em", maxHeight:"2em", overflowX:"visible"}}>
                      <div id="marquee" data-marquee-duration="20" style={{overflowX:"hidden"}}>
                      {removeMarquee && (<div className="marquee__inner">
                          <div className="marquee__content" style={{fontSize:"18px", display:"flex", alignItems:"center", whiteSpace:"nowrap" }}>
                    
                              <img src={GoogleFbImg} style={{maxWidth:"50px", padding:"0 10px"}} /> 4.5

                              <span style={{padding:"0 10px"}}>Rated</span>
                              <svg style={{maxWidth:"18px", maxHeight:"18px", marginLeft:"60px"}} fill="#12765e" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 191.667 191.667" ><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/> </g></svg>
                              <span style={{padding:"0 10px"}}>100% Customized Trips</span>
                              <svg style={{maxWidth:"18px", maxHeight:"18px", marginLeft:"60px"}} fill="#12765e" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 191.667 191.667" ><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/> </g></svg>
                              <span style={{padding:"0 10px"}}>95% Visa Success Rate</span>
                              <svg style={{maxWidth:"18px", maxHeight:"18px", marginLeft:"60px"}} fill="#12765e" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 191.667 191.667" ><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/> </g></svg>
                              <span style={{padding:"0 10px"}}>24x7 Concierge</span>
                          </div>
                      </div>)}
                      </div>
                  </section>
                {/* </div>  */}
            </div>
            
            <div style={{background:"#f8f8f8"}}>
              <HomepageSlider dataSlider={northIndiaData} title="North India Tours"/>
              <HomepageSlider dataSlider={southIndiaData} title="South India Tours"/>
              <HomepageSliderCreatives dataSlider={creatives} title="Latest Tours"/>
              <CarasoulCards/>
              {/* ZZAA */}
              <div className="flex-row-column" style={{justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                <HomepageVideoSlider data={videoData}/>
              </div>
              <HomepageBackgroundColorSection title="QUICK GETAWAY DESTINATIONS" description="Ideal for 3-5 days trip" data={europeanData.slice(1,11)}/>
              <HomepageBackgroundColorSectionWeb title="QUICK GETAWAY DESTINATIONS" description="Ideal for 3-5 days trip" data={quickGetawayData}/>
              <HomepageSlider dataSlider={VisaArrivalData} title="Visa on arrival destinations"/>
              <HomepageBackgroundColorSection title="EXPLORATIONS FAR AWAY" description="Ideal for 5-14 days trip" data={oceaniaData.slice(1,7)}/>
              <HomepageBackgroundColorSectionWeb title="EXPLORATIONS FAR AWAY" description="Ideal for 5-14 days trip" data={oceaniaData.slice(1,7)}/>
              {/* <HomeSingleVideo data={videoData}/> */}
              <HomepageSlider dataSlider={oceaniaData} title="OCEANIA"/>
              <HomepageSlider dataSlider={europeanData} title="EUROPEAN HOLIDAYS"/>
              <div className="home-travellers-head">
                  <HomepageVacationForTravellers title="HOLIDAYS FOR ALL" description="Budget" data={VisaArrivalData.slice(5,9)} />
              </div>
              <HomepageSlider dataSlider={VisaArrivalData} title="SOUTH EAST ASIAN VACATIONS"/>
              <HomepageCreative data={welcomeFlight}/>
              <HomepageSlider dataSlider={ScandinaviaData} title="Scandinavia"/>
              <div className="home-travellers-head">
                <HomepageVacationForTravellers title="VACATION FOR ALL" description="Travellers" data={VacationForAll} />
              </div>
              <HomepageSlider dataSlider={MiddleEastData} title="MIDDLE EAST"/>
              <TrustTheExperts/>
              {/* <Form/> */}
              
              <div style={{marginBottom:"5%", minHeight:"100px"}}>
                <button className="main-btn-rect popup-btn contact-btn-home" id="btnOpenForm" style={{textDecoration:"none", color:"white", fontFamily:"Lato", fontWeight:"700", zIndex:"999"}}>Contact Us!</button>
              </div>
              <ContactContiki formData={formData} updateFormData={updateFormData}/>
              <FooterMenu/>
              <Footer/>
            </div>
        </>
    )
};

export default Homepage;
